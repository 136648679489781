.LoginWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 16px;
}
.LoginBox {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.LoginAction {
  margin-top: 16px;
}
