@import '@/styles/Colors.module.scss';

.Header {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: auto 1fr 90px;
  height: 90px;
  width: 100%;
  background-color: white;
  padding: 0 64px;

  .LogoBlock {
    display: flex;
    gap: 24px;
    align-items: center;

    img {
      width: 88px;
    }
  }

  form {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .RightSide {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-self: flex-end;

    .NotificationWrap {
      position: relative;

      .Indicator {
        position: absolute;
        left: 29px;
        top: 16px;

        width: 8px;
        height: 8px;
        background-color: $red-CC;
        border-radius: 50%;
      }
    }
  }
}

.SidebarBody {
  padding: 27px 64px;

  .SidebarHead {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 44px;

    .Logo {
      width: 88px;
    }
  }

  .List {
    display: grid;
    gap: 24px;

    h2 {
      cursor: pointer;
      margin: 0;

      a {
        text-decoration: none;
        color: $black-11;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
